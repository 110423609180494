<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" v-if="ariaModal">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <form @submit.prevent="feedbackDataStore" class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all">
        <div class="text-right">
            <button type="submit" class="mt-2 mr-2 rounded-md shadow-sm text-red-500" @click="ariaModal = false">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </button>
        </div>
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex sm:items-start  md:justify-center md:items-center">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-2xl text-gray-900 font-mono text-center" id="modal-title">Feedback  </h3>
              <div class="mt-2">
                <div v-for="(question, key) in feedbacks.questions" :key="key" class="flex flex-col justify-center items-center mx-auto" >
                  <div>{{ question }}</div> 
                  <Rating :numberOfStar="rating.length" v-model="rating[key]" required/>
                </div>
              </div>
            </div>
          </div>
            <div class="w-full">
              <InputTextArea v-model="form.note" class="w-full border focus:outline-none p-2" placeholder="Anything else to mention .... " />
            </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="submit"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import Rating from "./Rating.vue"
import InputTextArea from "../../../../components/textarea.vue"
export default {
    components:{
        Rating,
        InputTextArea
    },
     data() {
        return {
          rating: [],
          form : {
            appointment_id: '',
            fq_id: '',
            note:''

          },
          ariaModal: true
        }
    },

    created(){
        this.$store.dispatch('schedule/getPreviousHistory');
    },


    watch:{
      '$store.state.schedule.previousHistory.last_appointment'( data ){
        this.form.appointment_id = data.id
        this.$store.dispatch('feedback/patientFeedbackQuestion',{
            appointment_id : data.id
        })
      },
      '$store.state.feedback.patientQuestions.questions'(data){
        this.form.fq_id = data.id
      }
      
    },

    computed: {
        feedbacks(){
            const question = this.$store.state.feedback.patientQuestions.questions || '';
            return question || '';
        },
    },
    methods:{
       feedbackDataStore () {
        if(Array.isArray(this.rating) ? this.rating.length >= 3 : false){
          this.$emit('childToParent', this.form, this.rating)
          this.ariaModal = false;
        }else{
          alert('Rating must be selected')
        }
      }
    }
}
</script>

<style>

</style>