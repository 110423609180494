<template>
  <div
    class="w-full md:max-w-6xl p-2 mx-auto md:flex justify-around flex-wrap items-center bg-white rounded-lg shadow-md overflow-hidden"
  >
    <div class="w-full md:w-1/2 md:p-4">
      <h2 class="text-3xl md:text-3xl text-brand font-bold">
        Welcome to GCPSC
      </h2>
      <span class="hidden md:block text-brand sm:text-center font-bold text-lg"
        >(GENESIS Career & Psychological Support Centre)</span
      >
      <div class="hidden md:block">
        <img
          src="../../../assets/image/wall.png"
          alt="picture"
          class="sx-invisible"
        />
      </div>
    </div>
    <!-- {{ $store.state.schedule.previousHistory }} -->
    <!-- {{ chamberSlot ? chamberSlot.slot_remains :''  }} -->
    <div class="pt-1 w-full md:w-1/2 md:p-4">
      <span class="text-xl md:text-2xl font-bold text-brand mb-2 md:mb-4">
        Appointment Schedule
      </span>
      <div>
        <div class="w-full my-4 border-b-2 pb-4">
          <div class="flex pb-3">
            <div class="flex-1"></div>

            <div class="flex-1">
              <div
                class="w-10 h-10 bg-white text-gray-300 border-2 mx-auto rounded-full text-lg flex items-center justify-center"
                :class="{
                  'transition-all ease-out duration-1000 bg-brand !text-brand':
                    support_type_id,
                }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>

            <div
              class="w-1/6 align-center items-center align-middle content-center flex"
            >
              <div
                class="w-full rounded bg-gray-300 items-center align-middle align-center flex-1"
                :class="{
                  'transition-all ease-out duration-1000 bg-brand': date,
                }"
              >
                <div
                  class="text-xs leading-none py-1 text-center text-grey-darkest rounded"
                  style="width: 100%"
                ></div>
              </div>
            </div>

            <div class="flex-1">
              <div
                class="w-10 h-10 bg-white text-gray-300 border-2 mx-auto rounded-full text-lg flex items-center justify-center"
                :class="{
                  'transition-all ease-out duration-1000 bg-brand !text-brand':
                    date,
                }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>

            <div
              class="w-1/6 align-center items-center align-middle content-center flex"
            >
              <div
                class="w-full bg-gray-300 rounded items-center align-middle align-center flex-1"
                :class="{
                  'transition-all ease-out duration-1000 !bg-brand':
                    chamberId && date,
                }"
              >
                <div
                  class="bg-gray-500 text-xs leading-none py-1 text-center text-grey-darkest rounded"
                  style="width: 0%"
                ></div>
              </div>
            </div>

            <div class="flex-1">
              <div
                class="w-10 h-10 bg-white text-gray-300 border-2 mx-auto rounded-full text-lg flex items-center justify-center"
                :class="{
                  'transition-all ease-out duration-1000 bg-brand !text-brand':
                    chamberId && date,
                }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>

            <div
              class="w-1/6 align-center items-center align-middle content-center flex"
            >
              <div
                class="w-full bg-gray-300 rounded items-center align-middle align-center flex-1"
                :class="{
                  'transition-all ease-out duration-1000 !bg-brand':
                    scheduleId && chamberId && date,
                }"
              >
                <div
                  class="bg-gray-500 text-xs leading-none py-1 text-center text-grey-darkest rounded"
                  style="width: 0%"
                ></div>
              </div>
            </div>

            <div class="flex-1">
              <div
                class="w-10 h-10 bg-white text-gray-300 border-2 mx-auto rounded-full text-lg flex items-center justify-center"
                :class="{
                  'transition-all ease-out duration-1000 bg-brand !text-brand':
                    scheduleId && chamberId && date,
                }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>

            <div class="flex-1"></div>
          </div>

          <div class="flex text-xs content-center text-center">
            <div class="w-1/4">Service Type</div>

            <div class="w-1/4">Date</div>

            <div class="w-1/4">Place</div>

            <div class="w-1/4">Time</div>
          </div>
        </div>
      </div>
      <div
        v-if="!support_type_id"
        class="text-md text-red-500 py-1 rounded-md text-md"
      >
        At first please choose
        <strong class="text-lg uppercase">service type</strong> you need!
      </div>
      <div
        class="bg-gray-200 px-4 py-2 gap-2 grid grid-cols-2 md:justify-around items-center md:mx-auto rounded mt-2"
      >
        <label
          v-for="type in suportType"
          :key="type.id"
          class="md:flex-1"
          @change="isNotLoginedIn()"
        >
          <div
            :class="{ '!bg-brand !text-white': support_type_id == type.id }"
            class="cursor-pointer bg-white text-brand overflow-hidden relative text-center p-2 shadow rounded-md hover:shadow-lg"
          >
            <input
              type="radio"
              v-model="support_type_id"
              @change="onChange"
              :value="type.id"
              class="absolute -left-full"
            />
            <span class="md:p-2 text-sm md:text-lg">{{ type.name }}</span>
          </div>
        </label>
      </div>
      <div
        v-if="!date && support_type_id"
        class="text-md text-red-500 py-1.5 rounded-sm text-md"
      >
        Please select available <strong class="uppercase">date</strong> &
        <strong class="uppercase">place</strong> of your choice
      </div>
      <div :class="{ 'opacity-20 cursor-not-allowed': !support_type_id }">
        <div class="mt-2">
          <DatePicker
            :available-dates="availableDates"
            v-model="date"
            :attributes="attrs"
            is-expanded
          />
        </div>
        <!-- reminder message -->
        <div>
          <div
            v-if="schedules.slot_massage && date"
            class="text-md text-red-500 py-1 rounded-md text-md"
          >
            <div>{{ schedules.slot_massage }}.</div>
            <div>If emergency, please contact us at 01404432565</div>
          </div>
          <div
            v-if="!schedules.slot_massage && date && !chamberId"
            class="text-md text-red-500 py-1 rounded-md text-md"
          >
            Please choose a <strong class="uppercase">Place</strong>
          </div>
        </div>
        <div>
          <div class="grid grid-cols-2 md:grid-cols-3 gap-2 mt-2">
            <label v-for="chamber in availableChamber" :key="chamber.id">
              <div
                :class="{
                  'bg-brand !text-white': chamberId == chamber.id,
                  '!opacity-40 cursor-not-allowed': !chamber.status,
                  'cursor-not-allowed': schedules.slot_massage,
                }"
                class="cursor-pointer overflow-hidden relative text-center border py-1 text-lg rounded-lg shadow"
              >
                <input
                  type="radio"
                  v-model="chamberId"
                  :value="chamber.id"
                  @click="chamberToggle(chamber.id)"
                  class="absolute -left-full"
                  v-if="chamber.status && chamber.status.slot_remains > 0"
                />
                <span class="md:p-2 text-sm md:text-md">{{
                  chamber.name
                }}</span>
              </div>
            </label>
          </div>
        </div>
        <div
          v-if="!scheduleId && chamberId && date && support_type_id"
          class="text-md text-red-500 py-1 rounded-md text-md text-left"
        >
          Please choose a <strong class="uppercase">Time</strong> slot
        </div>
        <div class="grid md:grid-cols-3 gap-2 mt-2">
          <label v-for="time in availableTime" :key="time.id">
            <div
              :class="{ 'bg-brand !text-white': scheduleId == time.id }"
              class="cursor-pointer overflow-hidden relative text-center border py-1 text-lg rounded-lg shadow"
            >
              <input
                type="radio"
                v-model="scheduleId"
                :value="time.id"
                class="absolute -left-full"
              />
              <span class="p-2 text-xs md:text-sm"
                >{{ time.time_schedule.start_time }} -
                {{ time.time_schedule.end_time }}</span
              >
            </div>
          </label>
        </div>
      </div>

      <div
        class="p-4"
        :class="{ 'opacity-20 cursor-not-allowed': !support_type_id }"
      >
        <btn
          variant="bg-brand"
          type="submit"
          @click="toggleModel = !toggleModel"
          title="Next"
          class="bg-brand text-white text-base"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </svg>
        </btn>
      </div>
    </div>
  </div>
  <div>
    <div
      class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50"
      v-if="toggleModel"
    >
      <div
        class="relative p-4 w-full max-w-2xl h-full md:h-auto"
        v-if="isLoggedIn && support_type_id && chamber && scheduleId"
      >
        <form
          @submit.prevent="postData()"
          class="bg-white w-full rounded shadow-2xl flex flex-col"
        >
          <div class="modal-body relative p-4">
            <div class="text-right">
              <button
                class="-mt-2 mb-2 text-center px-2 text-red-500"
                @click="toggleModel = false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="bg-brand p-2 text-2xl rounded-md text-white">
              Appointment Confirmation
            </div>
            <div class="flex flex-col justify-center items-center gap-2">
              <div class="flex">
                <label for="" class="">Chamber : </label>
                <div v-html="chamber" class="text-md ml-2"></div>
              </div>
              <div class="flex">
                <label for="" class="col-span-1">Date : </label>
                <span class="text-md ml-2" v-html="dates"></span>
              </div>
              <div class="flex">
                <label for="">Time : </label>
                <span v-html="stimeEtime" class="text-md ml-2"></span>
              </div>
              <div class="flex items-center mb-2 w-full overflow-hidden">
                <label for="" class="mr-2 hidden md:block"
                  >Mentor(opt) :
                </label>
                <Select
                  class="border text-md overflow-scroll"
                  v-model="requested_mentor_id"
                >
                  <option value="">Select Your Mentor</option>
                  <option
                    :value="mentor.id"
                    v-for="mentor in mentors"
                    :key="mentor.id"
                  >
                    <span class="text-sm">{{ mentor.name }}</span>
                  </option>
                </Select>
              </div>
            </div>
            <div>
              <div
                class="flex justify-between items-center mb-2"
                v-for="(question, index) in questions"
                :key="index"
              >
                <InputTextArea
                  class="w-4/5 py-2 p-2 border focus:outline-none"
                  v-model="questions[index]"
                  placeholder="Please share your problem with us to make the counselling session best effective. Your all informations will be kept confidential."
                  rows="4"
                  required
                />
                <button
                  class="w-2/12 text-center px-2 text-red-500"
                  @click="remove(index)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="text-left">
              <button
                type="button"
                @click="addQuestions()"
                class="p-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white border border-blue-500 hover:border-transparent rounded"
              >
                Add More Question
              </button>
            </div>
          </div>
          <div></div>
          <div
            class="modal-footer flex md:flex-shrink-0 flex-col md:flex-wrap lg:flex-wrap items-center justify-between p-4 border-t border-gray-200 rounded-b-md gap-2"
          >
            <div class="text-sm md:text-md">
              <input type="checkbox" v-model="trems_conditions" />
              <span>
                I agree to the
                <a
                  href="trems-and-conditions"
                  class="cursor-pointer hover:underline text-brand"
                  target="_blank"
                  >Terms and Conditions</a
                ></span
              >
            </div>
            <button
              type="submit"
              :class="{
                'bg-blue-600/60 cursor-not-allowed': !trems_conditions,
              }"
              class="bg-blue-600 items-center gap-4 text-white font-medium py-1.5 px-2 text-md leading-snug rounded focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              :disabled="!trems_conditions"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
      <div v-if="!isLoggedIn && suportType">
        <div class="bg-white w-full rounded shadow-2xl flex flex-col">
          <div class="w-2xl">
            <button
              type="button"
              class="float-right rounded-full text-red-600 md:-m-2 transition duration-100 ease-in-out"
              @click="toggleModel = false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div class="text-xl text-red-500 p-4">
              <span>Please Join First To An Appointment</span>
              <br />
              <router-link class="text-blue-500 underline text-2xl" to="/join"
                >Go To Join
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoggedIn && !date">
        <div class="bg-white w-full rounded shadow-2xl flex flex-col">
          <div class="w-2xl">
            <button
              type="button"
              class="float-right text-red-600 rounded-full h-8 w-8 transition duration-100 ease-in-out"
              @click="toggleModel = false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div class="text-xl text-red-500 p-4">
              <span>Please Select Your Suitable Date </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoggedIn && !chamberId && date">
        <div class="bg-white w-full rounded shadow-2xl flex flex-col">
          <div class="w-2xl">
            <button
              type="button"
              class="float-right rounded-full text-red-600 transition duration-100 ease-in-out"
              @click="toggleModel = false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div class="text-xl text-red-500 p-4">
              <span>Please Choose Chamber Location</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoggedIn && chamberId && date && !scheduleId">
        <div class="bg-white w-full rounded shadow-2xl flex flex-col">
          <div class="w-2xl">
            <button
              type="button"
              class="float-right rounded-full text-red-600 transition duration-100 ease-in-out"
              @click="toggleModel = false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div class="text-xl text-red-500 p-4">
              <span>Please Choose Time Slot</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50"
      v-if="isNotLogined"
    >
      <div v-if="!isLoggedIn && suportType">
        <div class="w-full wax-w-2xl rounded shadow-2xl flex flex-col">
          <div class="max-w-4xl">
            <div class="w-full bg-yellow-500 text-2xl text-white p-6">
              <div class="p-5 md:text-4xl">
                Please Join First To Create An Appointment
              </div>
              <br />
              <router-link
                class="bg-green-600 rounded-md text-white p-2 text-xl uppercase"
                to="/join"
                >Go To Join
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="absolute inset-0 z-40 opacity-25 bg-gray-400"
      v-if="toggleModel"
    ></div>
  </div>
  <div v-if="last_appointment_feedback">
    <div v-if="!mentorId && isLoggedIn">
      <feedback @childToParent="feedbackDataStore" />
    </div>
  </div>

  <div class="flex justify-center items-center w-full p-4">
    <carousel @prev="prev" @next="next" :visibleSlider="visibleSlider">
      <carousel-slide
        v-for="(type, index) in suportType"
        :key="index"
        :index="index"
        :visibleSlider="visibleSlider"
        :direction="direction"
        class="carousel-slider"
      >
        <!-- <img :src="slide" :alt="slide" /> -->

        <div
          class="uppercase text-brand font-brand font-bold bg-white p-4 max-w-6xl mx-auto mb-2 rounded-lg text-2xl"
        >
          Our Mentor List
        </div>

        <div>
          <div class="md:max-w-6xl mx-auto grid md:grid-cols-12 gap-4">
            <div
              class="md:col-span-3 bg-white shadow-md p-2 rounded-md"
              v-for="mentor_list in mentor_lists"
              :key="mentor_list.id"
            >
              <div v-if="mentor_list.gender === 2"
                class="w-24 h-24 border border-green-500 rounded-full p-1 mx-auto"
              >
                <img
                  alt="Vue logo"
                  src="../../../assets/image/female.jpg"
                  class="text-green-500 rounded-full"
                />
              </div>
              <div v-if="mentor_list.gender === 1"
                class="w-24 h-24 border border-green-500 rounded-full p-1 mx-auto"
              >
                <img
                  alt="Vue logo"
                  src="../../../assets/image/user.jpg"
                  class="text-green-500 rounded-full"
                />
              </div>
              <div>{{ mentor_list.name || "Empty" }}</div>
              <div class="text-sm text-brand">{{ type.name || "Empty" }}</div>
              <!-- <hr class="w-full border" /> -->
              <div class="text-sm">
                <span v-html="mentor_list.medical"></span>
              </div>
              <div class="text-sm">
                <span v-html="mentor_list.designation"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="max-w-6xl mx-auto" v-if="visibleSlider == 1">
          <router-link
            to="/mentors"
            class="text-brand right-0 flex justify-end p-2"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-brand"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
              </svg></router-link>
        </div>
      </carousel-slide>
    </carousel>
  </div>
</template>

<script>
import Btn from "../../../components/btn.vue";
import Select from "../../../components/select.vue";
import InputText from "../../../components/input-text.vue";
import InputTextArea from "../../../components/input-text-area.vue";
import Spinner from "../../../components/spinner.vue";
import { Calendar, DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";
import axios, { isLoggedIn } from "../../../store/axios";

import feedback from "./feedback/form.vue";

import Carousel from "../../../components/Carousel.vue";

import CarouselSlide from "../../../components/CarouselSlide.vue";

export default {
  props() {},
  components: {
    Btn,
    InputText,
    Spinner,
    Btn,
    InputTextArea,
    DatePicker,
    Calendar,
    Select,
    feedback,
    Carousel,
    CarouselSlide,
  },
  data() {
    return {
      toggleModel: false,
      centertoggleModel: false,
      historyModel: false,
      isNotLogined: false,
      chamberId: "",
      date: "",
      scheduleId: "",
      txt: "",
      value: 1,
      user_id: this.$store.getters["auth/user"].id,
      questions: [""],
      type: "",
      requested_mentor_id: "",
      trems_conditions: false,
      support_type_id: "",
      slot: this.chamberSlot ? chamberSlot.slot_remains : 0,
      mentorId: "",
      dateTime: "",
      rating: [],
      visibleSlider: 0,
      direction: "left",
    };
  },



  created() {
    this.$store.dispatch("schedule/getPreviousHistory");

    this.$store.dispatch("schedule/getSchedules");
  },

  watch: {
    date(newDate, oldDate) {
      this.searchSchedule();
    },
    chamberId(newChamberId, oldChamberId) {
      this.searchSchedule();
    },
    dateTime(newChamberId, oldChamberId) {
      this.searchSchedule();
    },
    hasMentor() {
      this.mentorId = this.hasMentor ? true : false;
    },
  },

  computed: {
    schedules() {
      return this.$store.state.schedule.schedules;
    },

    mentors() {
      return this.schedules.mentors
        ? this.schedules.mentors[this.support_type_id]
        : "";
    },
    mentor_lists() {
      if (this.visibleSlider == 0) {
        return this.schedules.mentors ? this.schedules.mentors[3].slice(0,4) : "";
      }
      if (this.visibleSlider == 1) {
        return this.schedules.mentors ? this.schedules.mentors[4].slice(0,4) : "";;
      }
    },

    suportType() {
      const type = this.$store.state.schedule.previousHistory;
      return type.support_types;
    },
    lastAppointment() {
      const type = this.$store.state.schedule.previousHistory;
      return type.last_appointment || "";
    },
    last_appointment_feedback() {
      const type = this.lastAppointment || "";
      //   return this.now;
      return type.schedule
        ? type.schedule.date < this.now
          ? true
          : false
        : "";
    },
    hasMentor() {
      return this.lastAppointment.user_feedbacks;
    },
    availableDates() {
      const schedules = this.schedules;
      return schedules.dates
        ? Object.values(schedules.dates).map((date) =>
            date.status ? date.date : ""
          )
        : [];
    },

    availableChamber() {
      const schedules = this.schedules;
      return schedules.chambers;
    },
    chamber() {
      const chambers = Object.values(this.availableChamber).filter(
        (ch) => ch.id == this.chamberId
      );
      const chamber = Object.values(chambers).map((chamber) => chamber.name);
      return chamber;
    },
    chamberSlot() {
      if (this.date) {
        const slot = this.schedules.chambers || "";
        const found = slot.filter((element) => element.status !== null);
        if (found[0]) {
          return found[0].status || "";
        }
      }
    },
    availableTime() {
      const schedules = this.$store.state.schedule.schedules;
      return schedules.schedules
        ? Object.values(schedules.schedules).map((schedule) => schedule)
        : [];
    },

    time() {
      const times = Object.values(this.availableTime).filter(
        (ch) => ch.id == this.scheduleId
      );
      const time = Object.values(times).map((time) => time.time_schedule);
      return time;
    },

    stimeEtime() {
      const stime = Object.values(this.time).map((stime) => stime.start_time);
      const etime = Object.values(this.time).map((stime) => stime.end_time);
      return `${stime} - ${etime}`;
    },
    attrs() {
      const schedules = this.$store.state.schedule.schedules;
      const dates = schedules.dates
        ? Object.values(schedules.dates).map((date) =>
            date.status ? date.date : ""
          )
        : [];
      return [
        {
          highlight: {
            color: "orange",
            fillMode: "light",
            contentClass: "italic",
          },

          //     popover: {
          //     label: 'This date you can Booked!',
          //     visibility: 'hover',
          //     hideIndicator: true,
          // },

          dates: dates,
        },
      ];
    },
    dates: {
      get() {
        return this.formatDate(this.date);
      },
    },
    isLoggedIn() {
      return this.$store.getters["auth/authenticated"];
    },

    now() {
      let date = new Date();
      let month = "" + (date.getMonth() + 1);
      let day = "" + date.getDate();
      let year = date.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }

      if (day.length < 2) {
        day = "0" + day;
      }

      return [year, month, day].join("-");
    },
  },

  methods: {
    async postData() {
      await axios()
        .post("/api/appointments", {
          schedule_id: this.scheduleId,
          type: this.support_type_id,
          user_id: this.user_id,
          questions: this.questions,
          requested_mentor_id: this.requested_mentor_id,
          trems_conditions: this.trems_conditions,
        })
        .then(() => {
          this.$toast.success(
            `Your appointment is successfully submitted !!!`,
            {
              position: "top",
              duration: 3000,
            }
          );
          this.$router.push("/my/my-profile");
        })
        .catch((error) => {
          this.$toast.error(`You have already booked an appointment !!!`, {
            position: "bottom-right",
            duration: 1000,
          });
          this.$router.push("/my/my-profile");
        });
    },

    // feedbackDataStore() {
    //   console.log();
    // },

    searchSchedule() {
      this.$store.dispatch("schedule/getSchedules", {
        support_type_id: this.support_type_id,
        chamber_id: this.chamberId || "",
        date: this.date ? this.formatDate(this.date) : "",
        scheduleId: this.scheduleId || "",
      });
    },

    formatDate(input) {
      let date = new Date(input);
      let month = "" + (date.getMonth() + 1);
      let day = "" + date.getDate();
      let year = date.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }

      if (day.length < 2) {
        day = "0" + day;
      }

      return [year, month, day].join("-");
    },

    addNewField() {
      this.questions.push("");
    },

    removeField(index) {
      this.questions.splice(index, 1);
    },

    addQuestions() {
      this.questions.push("");
    },

    remove(index) {
      this.questions.splice(index, 1);
    },
    onChange() {
      this.date = "";
      this.chamberId = "";
      this.scheduleId = "";

      this.$store.dispatch("schedule/getSchedules", {
        support_type_id: this.support_type_id,
      });
    },
    chamberToggle(data) {
      console.log(this.chamberId != data);
      // console.log(this.chamberId);
      // console.log(data);
      this.scheduleId = "";

      if (this.chamberId != data) {
        this.chamberId = data;
      } else {
        this.chamberId = "";
      }
    },
    isNotLoginedIn() {
      if (!this.isLoggedIn && this.support_type_id) {
        this.isNotLogined = !this.isNotLogined;
      }
    },
    feedbackDataStore(value1, value2) {
      this.$store.dispatch("feedback/feedbackSubmit", {
        form_data: value1,
        rating: value2,
      });

      // feedbackSubmit
      //  this.rating = value
    },

    next() {
      console.log("this.slidesLen", this.slidesLen);
      if (this.visibleSlider >= this.slidesLen - 1) {
        this.visibleSlider = 0;
      } else {
        this.visibleSlider++;
      }
      this.direction = "left";
    },
    prev() {
      if (this.visibleSlider <= 0) {
        this.visibleSlider = this.slidesLen - 1;
      } else {
        this.visibleSlider--;
      }
      this.direction = "right";
    },
  },
};
</script>
<style></style>
