<template>
  <div class="carousel max-w-7xl w-full mx-auto">
    <slot></slot>
    <btn
      v-if="visibleSlider == 1"
      @click="prev"
      class="prev absolute p-2 left-0 text-center bg-brand rounded-full text-white"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15 19l-7-7 7-7"
        /></svg
    ></btn>
    <btn
      v-if="visibleSlider == 0"
      @click="next"
      class="next absolute p-2 bg-brand rounded-full text-white right-0 text-center"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9 5l7 7-7 7"
        /></svg
    ></btn>
  </div>
</template>
<script>
export default {
  props: ["visibleSlider"],

  data() {
    return {};
  },
  computed: {},
  methods: {
    next() {
      this.$emit("next");
    },
    prev() {
      this.$emit("prev");
    },
  },
};
</script>

<style>
.carousel {
  position: relative;
  height: 350px;
  overflow: hidden;
}
btn {
  top: calc(50% - 20px);
}
btn:focus,
btn:hover {
  outline: none;
  cursor: pointer;
}
</style>
