<template>
  <div>
    <transition :name="direction" mode="in-out">
      <div
        class="carousel-slider overflow-scroll md:overflow-auto"
        v-show="visibleSlider === index"
      >
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["visibleSlider", "index", "direction"],
  data() {
    return {};
  },
  computed: {},
};
</script>

<style>
.carousel-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.left-enter-active {
  animation: leftInAnimation 0.4s ease-in;
}
.left-leave-active {
  animation: leftOutAnimation 0.4s ease-in;
}
@keyframes leftInAnimation {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes leftOutAnimation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.right-enter-active {
  animation: RightInAnimation 0.4s ease-in;
}
.right-leave-active {
  animation: RightOutAnimation 0.4s ease-in;
}

@keyframes RightInAnimation {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes RightOutAnimation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
